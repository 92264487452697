import React, { useEffect } from "react";
import "./MatchCard.css";
import betclic from "../../img/BetclickElite/Betlicks.png";
import coupeFrance from "../../img/competitions/CDF_logo.png";
import basketballChampionsLeague from "../../img/competitions/BasketBallChampionsLeague.png";
import { Img } from "react-image";

const MatchCard = (props) => {
  return (
    <div className={"match_card"}>
      <div className={"match_card_content"}>
        <div>
          <div className="flex space_around">
            <div className="match_infos">
              <p>
                {props.date} {props.month}
                <br />
                {props.hours ? props.hours : ""}
                <br />
                <span className={"match_infos_competition_name"}>
                  {props.competition}
                </span>
                <br />
                <span className={"match_infos_localisation"}>
                  {props.place}
                </span>
              </p>
            </div>
            <div className="flex">
              <div className="team_logo team_logo_blur">
                <p>{props.domicileTeamName}</p>
                <Img src={props.domicileTeamLogo} alt="" />
                {props.homeBasedTeamScore && (
                  <div
                    className={"match_score"}
                    style={{
                      color: `${
                        parseInt(props.homeBasedTeamScore, 10) > parseInt(props.outsideTeamScore, 10)
                          ? "#d6b357"
                          : ""
                      }`,
                    }}
                  >
                    {props.homeBasedTeamScore}
                  </div>
                )}
              </div>
              <div className={"versus"}>
                <span className={"versus_text"}>VS</span>
                {props.resume && (
                  <a href={props.resume}>
                    <div className={"mach_sheet"}>Feuille de match</div>
                  </a>
                )}
              </div>
              <div className="team_logo">
                <p>{props.externTeamName}</p>
                <Img src={props.externTeamLogo} alt="" />
                {props.outsideTeamScore && (
                  <div
                    className={"match_score"}
                    style={{
                      color: `${
                        parseInt(props.homeBasedTeamScore, 10) < parseInt(props.outsideTeamScore, 10)
                          ? "#d6b357"
                          : ""
                      }`,
                    }}
                  >
                    {props.outsideTeamScore}
                  </div>
                )}
              </div>
            </div>
            <div className={"match_date_under"}>
              <p>
                {" "}
                {props.date} {props.month}
              </p>
            </div>

            <div className="competImageContainer">
              <Img
                src={
                  props.competition === "Betclic Elite"
                    ? betclic
                    : props.competition === "BASKETBALL CHAMPIONS LEAGUE"
                    ? basketballChampionsLeague
                    : coupeFrance
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchCard;
