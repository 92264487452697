import React, { useEffect, useState } from "react";
import "./MainNavigation.css";
import { NavLink, useLocation } from "react-router-dom";
import {Img} from 'react-image';


const MainNavigation = () => {
  const logo = process.env.PUBLIC_URL + "/logo.png";

  const [showMenu, setShowMenu] = useState(false);
  const [drop, setDrop] = useState({
    club: false,
    team: false,
    partners: false,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const nav = document.querySelector("nav");
    const body = document.querySelector("body");

    window.addEventListener("scroll", function () {
      if (body.getBoundingClientRect().top === 0) {
        nav.classList.remove("black");
      } else {
        nav.classList.add("black");
      }
    });
  }, []);

  const handleClickShowMenu = () => {
    setShowMenu((prevState) => {
      const navMenu = document.querySelector(".nav__menu");
      if (prevState) {
        navMenu.style.display = "none";
      } else {
        navMenu.style.display = "block";
      }
      return !prevState;
    });
  };

  const handleLinkClick = () => {
    const dropShow = document.querySelectorAll(".dropdown__container");
    Array.from(dropShow).forEach((drop) => {
      drop.removeAttribute("style");
    });
    const navMenu = document.querySelector("#nav__menu");
    if (navMenu.classList.contains("show-menu")) {
      navMenu.classList.remove("show-menu");
    }

    const navToggle = document.querySelector(".nav__toggle");
    if (navToggle.classList.contains("show-icon")) {
      navToggle.classList.remove("show-icon");
    }
  };

  const handleClickDropDownBtn = (e) => {
    const dataDrop = e.target.getAttribute("data-drop");
    const allDrop = document.querySelectorAll(".dropdown__container");
    Array.from(allDrop).forEach((drop) => {
      const parentNode = drop.parentNode;
      if (parentNode.getAttribute("data-drop") !== dataDrop) {
        console.log();
        drop.removeAttribute("style");
        parentNode.classList.remove("show-dropdown");
      }
    });

    const parent = e.target.parentNode;
    const dropdownContainer = parent.querySelector(".dropdown__container");

    if (parent.classList.contains("show-dropdown")) {
      dropdownContainer.removeAttribute("style");
      parent.classList.remove("show-dropdown");
    } else {
      dropdownContainer.style.height = dropdownContainer.scrollHeight + "px";
      parent.classList.add("show-dropdown");
    }

    const newDrop = {
      club: false,
      team: false,
      partners: false,
    };
    newDrop[dataDrop] = true;
    setDrop((prevState) => newDrop);
  };

  return (
    <header className="header">
      <nav className="nav">
        <div className="nav__data">
          <NavLink to={"/"} className="nav__logo">
            <Img src={logo} alt="logo de saint quentin" />
          </NavLink>

          <div
            className={`nav__toggle ${showMenu ? "show-icon" : ""}`}
            id="nav__toggle"
            onClick={handleClickShowMenu}
          >
            <i className="ri-menu-line nav__toggle-menu"></i>
            <i className="ri-close-line nav__toggle-close"></i>
          </div>
        </div>

        <div
          className={`nav__menu ${showMenu ? "show-menu" : ""}`}
          id="nav__menu"
        >
          <ul className="nav__list">
            <li
              className={`dropdown__item ${drop.club ? "show-dropdown" : ""}`}
            >
              <div
                className="nav__link dropdown__button"
                data-drop={"club"}
                onClick={(e) => handleClickDropDownBtn(e)}
              >
                Club
                <i className="ri-arrow-down-s-line dropdown__arrow"></i>
              </div>

              <div className={`dropdown__container`}>
                <div className="dropdown__content">
                  <div className="dropdown__group">
                    <span className="dropdown__title">Histoire</span>

                    <ul className="dropdown__list">
                      <li>
                        <NavLink
                          to={"club/histoire/saint-quentin"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Histoire du club
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"club/info-pratiques/stade"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Pierre Ratte
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown__group">
                    <span className="dropdown__title">Vie du club</span>

                    <ul className="dropdown__list">
                      <li>
                        <a
                          className="dropdown__link"
                          href={"http://www.sqbb-jsc.fr/"}
                          target={"_blank"}
                        >
                          L'association
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown__group">
                    <span className="dropdown__title">En savoir plus</span>

                    <ul className="dropdown__list">
                      <li>
                        <NavLink
                          to={"/contact"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Contact
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/club/info-pratiques/general"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          infos pratiques
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li
              className={`dropdown__item ${drop.team ? "show-dropdown" : ""}`}
            >
              <div
                className="nav__link dropdown__button"
                data-drop={"team"}
                onClick={(e) => handleClickDropDownBtn(e)}
              >
                Saison 24-25{" "}
                <i className="ri-arrow-down-s-line dropdown__arrow"></i>
              </div>

              <div className={`dropdown__container`}>
                <div className="dropdown__content">
                  <div className="dropdown__group">
                    <span className="dropdown__title">l'équipe</span>

                    <ul className="dropdown__list">
                      <li>
                        <NavLink
                          to={"equipe/pro/joueurs"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Joueurs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"equipe/pro/staff"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Staff
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown__group">
                    <span className="dropdown__title">Compétitions</span>

                    <ul className="dropdown__list">
                      <li>
                        <NavLink
                          to={"equipe/competitions/betclics"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Betclic Elite
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"equipe/competitions/cdf"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Coupe de France
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"equipe/competitions/leaders-cup"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Leaders Cup
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown__group">
                    <span className="dropdown__title">Saison 24-25</span>

                    <ul className="dropdown__list">
                      <li>
                        <NavLink
                          to={"equipe/match/calendrier"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Calendrier et Résultats
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li
              className={`dropdown__item ${
                drop.partners ? "show-dropdown" : ""
              }`}
            >
              <div
                className="nav__link dropdown__button"
                data-drop={"partners"}
                onClick={(e) => handleClickDropDownBtn(e)}
              >
                Partenaires{" "}
                <i className="ri-arrow-down-s-line dropdown__arrow"></i>
              </div>

              <div className={`dropdown__container`}>
                <div className="dropdown__content">
                  <div className="dropdown__group">
                    <span className="dropdown__title">Partenaires</span>

                    <ul className="dropdown__list">
                      <li>
                        <NavLink
                          to={"partenaires/nos-partenaires"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Nos Partenaires
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"partenaires/devenir-partenaire"}
                          className="dropdown__link"
                          onClick={handleLinkClick}
                        >
                          Devenir partenaire
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to={"/actualites"}
                className="nav__link sqbb_ah_link"
                onClick={handleLinkClick}
              >
                Actus
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"https://shop-sqbb.fr"}
                className="nav__link sqbb_ah_link"
                onClick={handleLinkClick}
              >
                Boutique
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/cashless"}
                className="nav__link sqbb_ah_link"
                onClick={handleLinkClick}
              >
                <span className={"cashless-menu-btn"}>Cashless</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default MainNavigation;
