import React, {useEffect, useState} from 'react';
import './DesktopPlayer.css';
import AboutPlayer from "../../../components/PlayerDetails/AboutPlayer/AboutPlayer";
import PlayerResume from "../../../components/PlayerDetails/PlayerResume/PlayerResume";
import PlayerPhotos from "../../../components/PlayerDetails/PlayerPhotos/PlayerPhotos";
import {
    BiLogoFacebookCircle,
    BiLogoTwitter,
    BiLogoInstagram,
    BiLogoLinkedinSquare
} from "react-icons/bi";
import {Link, useLocation, useParams} from "react-router-dom";
import { playersData } from "../../../datas/players";
import logo from '../../../img/logo.png';
import { BiCaretLeftCircle } from "react-icons/bi";
import {Img} from 'react-image';


const DesktopPlayer = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    const params = useParams();
    const getCurrentPlayer = () => {
        return playersData.find(player => player.id.toString() === params.id.toString());
    }

    const [ currentPlayer, setCurrentPlayer ] = useState( getCurrentPlayer() );

    useEffect(() => {
        setCurrentPlayer(() => {
            return playersData.find(player => player.id.toString() === params.id.toString());
        })
    }, [ params.id ]);



    const [headerTop, setHeaderTop] = useState(false);

    const [activeItem, setActiveItem] = useState({
        about: false,
        resume: false,
        portfolio: false
    });

    const handleClick = (event) => {
        const name = event.target.dataset.name

        const newValue = {
            about: false,
            resume: false,
            portfolio: false
        }

        if(name === 'header'){
            setHeaderTop(false);
        } else {
            newValue[name] = true;
            setHeaderTop(true);
        }

        setActiveItem(prevState => newValue);
    }

    return (
        <div className={'player_details_box'}>
            <section id="header_player" className={headerTop ? 'header-top' : ''}>
                <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <div className={"pricipal"}>
                        <h1>{currentPlayer.firstName} {currentPlayer.lastName}</h1>
                        <h2>Joue au poste de <span>{ currentPlayer.position }</span></h2>

                        <nav id="navbar" className="navbar">
                            <ul>
                                <li><a className="nav-link active" href="#" data-name={'header'} onClick={ handleClick }>Résumé</a></li>
                                <li><a className="nav-link" href="#" data-name={'about'} onClick={ handleClick }>A propos</a></li>
                                <li><a className="nav-link" href="#" data-name={'resume'} onClick={ handleClick }>Carrière</a></li>
                                <li><a className="nav-link" href="https://www.facebook.com/profile.php?id=100063595659271&sk=photos&locale=fr_FR" target={"_blank"} data-name={'portfolio'}>Photos</a></li>
                            </ul>
                            <i className="bi bi-list mobile-nav-toggle"></i>
                        </nav>

                        <div className="social-links">
                            {
                                currentPlayer.sci.tweeter &&
                                <a href={ currentPlayer.sci.tweeter } target={'_blank'}><BiLogoTwitter/></a>
                            }
                            {
                                currentPlayer.sci.facebook &&
                                <a href={ currentPlayer.sci.facebook } target={'_blank'}><BiLogoFacebookCircle/></a>
                            }
                            {
                                currentPlayer.sci.instagram &&
                                <a href={ currentPlayer.sci.instagram } target={'_blank'}><BiLogoInstagram/></a>
                            }
                            {
                                currentPlayer.sci.linkedIn &&
                                <a href={ currentPlayer.sci.linkedIn } target={'_blank'}><BiLogoLinkedinSquare/></a>
                            }

                        </div>

                        <div id="line1"></div>
                        <div id="line2"></div>

                        <div className={`logo-box ${headerTop ? 'fixed-logo' : ''}`}>
                            <Link to={"/equipe/pro/joueurs"}><img src={ logo } alt=""/></Link>
                        </div>
                    </div>

                    <div id="image" className={headerTop ? 'hidden-bx' : ''}>
                        <Img src={currentPlayer.mainPageImg} alt={`Photo de ${currentPlayer.lastName}`}/>
                    </div>
                </div>

                <div id="sci-box">
                    <div id="icons">
                        <a href="https://twitter.com/SQBB_Officiel"  target={'_blank'}><BiLogoTwitter size={'35px'}/></a>
                        <a href="https://www.facebook.com/profile.php?id=100063595659271&locale=fr_FR" target={'_blank'}><BiLogoFacebookCircle size={'35px'}/></a>
                        <a href="https://www.instagram.com/sqbbofficiel/" target={'_blank'}><BiLogoInstagram size={'35px'}/></a>
                        <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fsqbb%2Fmycompany%2Fverification%2F%3FviewAsMember%3Dtrue%26fbclid%3DIwAR1hovYRQrg3TGNdSJV9b__H9ORUM5wISLE2pAys18wxFvPzotC39QMs1vs&h=AT3_iRLz6eP6aETtJRXlP0LK9pkwGH0V1L3ERWt-A43nvGaBAkcqu3jqxggPvCuo_pknbHbGhsTWpTmt83yA0zTj99v2XZIf9Oa9PFmYL6XHBoqLHuPJT6oSPNYXRdB2VgCpc68gjznqDWHJrP7EijA&__tn__=-UK*F" target={'_blank'}><BiLogoLinkedinSquare size={'35px'}/></a>
                    </div>
                </div>

            </section>

            <section id="about" className={`player_section about ${activeItem.about ? 'active section-show' : ''}` }>
                <AboutPlayer {...currentPlayer} />
            </section>

            <section id="resume" className={`player_section resume ${activeItem.resume ? 'active section-show' : ''}`}>
                <PlayerResume player={currentPlayer}/>
            </section>

            <section id="portfolio" className={`player_section portfolio ${activeItem.portfolio ? 'active section-show' : ''}` }>
                <PlayerPhotos player={currentPlayer}/>
            </section>
            
            <div className={"back_to_back"}>
                <Link to={"/equipe/pro/joueurs"}><BiCaretLeftCircle></BiCaretLeftCircle>Retour</Link>
            </div>
        </div>
    );
};

export default DesktopPlayer;